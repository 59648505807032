import React from 'react';
import { array, bool, object, string } from 'prop-types';
import WidgetHeader from '@/components/widgets/WidgetHeader';
import {
	getDataType,
	getWidgetCategory,
	getThemeClassName,
	getContentListFromGalleryFolders
} from '@/components/widgets/widgetHelpers';
import GridItem from './GridItem';
import './Grid.scss';

const config = {
	SQUARE_STACKED: { thumbType: '1x1' },
	SQUARE_FOUR_ACROSS: { thumbType: '1x1' },
	LANDSCAPE_STACKED: { thumbType: '16x9' },
	LANDSCAPE_FOUR_ACROSS: { thumbType: '16x9' }
};

/**
 * @function getOptions
 * @param {String} template
 * @returns {Object}
 */
const getOptions = (template) => {
	const options = config[template];

	if (!options) {
		console.log('widgetTemplate does not match config. Using default options');
		return { template: 'SQUARE_STACKED', thumbType: '1x1' };
	}
	return { template, ...options };
};

/**
 * @function Grid
 * @description Provides a Grid widget
 * @param {Boolean} [props.authorBylineEnabled]
 * @param {String} [props.className]
 * @param {Array} props.contentList
 * @param {Object} props.criteria
 * @param {String} [props.header]
 * @param {Object} [props.headerImage]
 * @param {Boolean} props.isCurated
 * @param {String} [props.position]
 * @param {Boolean} [props.timestampsEnabled]
 * @param {String} props.widgetTemplate
 * @param {String} [props.sectionType]
 * @param {String} [props.pageTheme]
 * @return {React.ReactElement}
 */
const Grid = ({
	authorBylineEnabled,
	className,
	contentList,
	criteria,
	header,
	headerImage,
	isCurated,
	position,
	timestampsEnabled,
	widgetTemplate,
	sectionType,
	pageTheme
}) => {
	const options = getOptions(widgetTemplate);
	const typeName = options.template.toLowerCase().replace(/_/g, '-');
	const isStacked = options.template.match(/STACKED/i);
	const analyticsData = {
		'data-widget-type': 'grid-carousel-widget',
		'data-widget-template': widgetTemplate,
		'data-widget-position': position,
		'data-widget-data-type': getDataType(criteria, isCurated),
		'data-widget-category': getWidgetCategory(criteria, isCurated)
	};

	// determine maximum width of thumbs in desktop
	let thumbWidth = '321';
	if (isStacked) {
		if (sectionType === 'FULL') {
			thumbWidth = '662';
		} else {
			thumbWidth = '435';
		}
	}

	const updatedContentList = getContentListFromGalleryFolders(contentList);

	return (
		<section
			className={`widget ${typeName} ${className || ''} ${getThemeClassName(pageTheme)}`}
			data-hook="grid"
			data-tb-region={`${widgetTemplate} ${position}`}
		>
			{header && <WidgetHeader title={header} thumbnail={headerImage} />}
			<div className="columns columns--flex-mobile">
				{updatedContentList.map((item, index) => {
					const { displayImage, displayTitle, reference, linkType } = item;

					if (!reference) return null;

					const { branding, id, snipe, uri, authors, publishDate } = reference;

					return (
						index < 4 && (
							<div
								key={id}
								className={`column is-6-mobile ${isStacked ? 'is-6' : ''}`}
								data-tb-region-item
							>
								<GridItem
									thumbnail={displayImage}
									id={id}
									uri={uri}
									width={thumbWidth}
									width2x="360"
									title={displayTitle}
									linkType={linkType}
									analyticsData={analyticsData}
									thumbType={options.thumbType}
									snipe={snipe}
									authors={authors}
									publishDate={publishDate}
									branding={branding}
									showSponsoredBy={isCurated}
									authorBylineEnabled={authorBylineEnabled}
									timestampsEnabled={timestampsEnabled}
								/>
							</div>
						)
					);
				})}
			</div>
		</section>
	);
};

Grid.propTypes = {
	authorBylineEnabled: bool,
	className: string,
	contentList: array.isRequired,
	criteria: object.isRequired,
	header: string,
	headerImage: object,
	isCurated: bool.isRequired,
	position: string,
	timestampsEnabled: bool,
	widgetTemplate: string.isRequired,
	sectionType: string,
	pageTheme: string
};

Grid.displayName = 'Grid';

export default Grid;
