import React from 'react';
import WidgetAuthor from '@/components/widgets/WidgetAuthor';
import WidgetTitle from '@/components/widgets/WidgetTitle';
import WidgetDate from '@/components/widgets/WidgetDate';
import Thumb from '@/components/thumb/Thumb';
import ContentItemIcon from '@/components/icons/ContentItemIcon';
import SponsoredBy from '@/components/ads/sponsored/SponsoredBy';
import Snipe from '@/components/snipe-banner/SnipeBanner';
import { array, bool, object, string, func } from 'prop-types';

/**
 * @function GridItem
 * @param {Object} props
 * @param {String} props.id
 * @param {Object} [props.thumbnail]
 * @param {String} [props.uri]
 * @param {String} props.title
 * @param {String} props.linkType
 * @param {Object} [props.analyticsData]
 * @param {String} [props.thumbType]
 * @param {String} [props.snipe]
 * @param {Array} [props.authors]
 * @param {String} [props.publishDate]
 * @param {Object} [props.branding]
 * @param {Boolean} [props.showSponsoredBy]
 * @param {Function} [props.onClick]
 * @param {String} [props.width]
 * @param {String} [props.width2x]
 * @param {Boolean} [props.showContentItemIcon]
 * @param {Boolean} [props.timestampsEnabled]
 * @param {Boolean} [props.authorBylineEnabled]
 * @return {React.ReactElement}
 */
const GridItem = ({
	id,
	thumbnail,
	uri,
	title,
	linkType,
	analyticsData,
	thumbType,
	snipe,
	authors,
	publishDate,
	branding,
	showSponsoredBy,
	onClick,
	children,
	width,
	width2x,
	showContentItemIcon,
	timestampsEnabled,
	authorBylineEnabled
}) => {
	return (
		<div className="grid__item">
			<div className="widget__thumb">
				{thumbnail ? (
					<Thumb
						analyticsData={analyticsData}
						type={thumbType}
						href={uri}
						thumbnail={thumbnail}
						videoId={linkType === 'VIDEO' ? id : null}
						width={width}
						width2x={width2x}
						onClick={onClick}
					>
						{snipe && <Snipe snipe={snipe} color="WHITE" />}
						{showContentItemIcon && <ContentItemIcon type={linkType} />}

						{children}
					</Thumb>
				) : (
					<div className="grid__placeholder" />
				)}
			</div>
			<WidgetTitle title={title} href={uri} analyticsData={analyticsData} onClick={onClick} />

			{authorBylineEnabled && <WidgetAuthor authors={authors} />}
			{timestampsEnabled && <WidgetDate date={publishDate} />}
			{showSponsoredBy && branding && <SponsoredBy {...branding} />}
		</div>
	);
};

GridItem.defaultProps = {
	showContentItemIcon: true,
	timestampsEnabled: true,
	authorBylineEnabled: true
};
GridItem.propTypes = {
	thumbnail: object,
	uri: string,
	id: string.isRequired,
	title: string.isRequired,
	linkType: string.isRequired,
	analyticsData: object,
	thumbType: string,
	snipe: string,
	authors: array,
	publishDate: string,
	branding: object,
	showSponsoredBy: bool,
	onClick: func,
	showContentItemIcon: bool,
	children: object,
	width: string,
	width2x: string,
	timestampsEnabled: bool,
	authorBylineEnabled: bool
};

GridItem.displayName = 'GridItem';

export default GridItem;
